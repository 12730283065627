<script setup>
import {useSendNewTicket} from "~/composables/general/sendNewTicket.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {storeToRefs} from "pinia";
import {useNotifications} from "~/stores/notifications.js";

const generalApp = useGeneralApp()
const userStore = useUserStore()
const notifications = useNotifications()
const { new_ticket_category,
  new_ticket_sub_category,
  new_ticket_emergency_type,
  new_ticket_description,
  new_ticket_zone,
  new_ticket_sub_zone} = storeToRefs(userStore)
const requiredFields = ref([
    userStore.newTicket.danger_category,
    userStore.newTicket.emergency_type,
    userStore.newTicket.description,
    userStore.newTicket.danger_zone,
    userStore.newTicket.danger_sub_zone
])
const markUnfilled = () => {
    for (let index in requiredFields.value) {
      if (requiredFields.value[index] === '') {
        notifications.intensiveMarkRequiredFields = true
        switch (index) {
          case "0": {
            new_ticket_category.value.focus()
            break
          }
          case "2": {
            new_ticket_emergency_type.value.focus()
            break
          }
          case "3": {
            new_ticket_description.value.focus()
            break
          }
          case "4": {
            new_ticket_zone.value.focus()
            break
          }
          case "5": {
            new_ticket_sub_zone.value.focus()
            break
          }
        }
        setTimeout(() => {
          notifications.intensiveMarkRequiredFields = false
        }, 3000)
        break
      }
    }
}
</script>

<template>
  <div v-if="((userStore.newTicket.danger_category !== '')&&
              (userStore.newTicket.danger_zone !== '')&&
              (userStore.newTicket.danger_sub_zone !== '')&&
              (userStore.newTicket.emergency_type !== '')&&
              (userStore.newTicket.description !== ''))"
      class="mb-[10px] w-[270px] text-white flex flex-col justify-center items-center direction-horizontal h-[35px] border-black bg-cement-orange">
    <button @click="useSendNewTicket()" class="min-w-full z-20  p-2">
      Отправить
    </button>
  </div>
  <div v-if="((userStore.newTicket.danger_category === '')||
              (userStore.newTicket.danger_zone === '')||
              (userStore.newTicket.danger_sub_zone === '')||
              (userStore.newTicket.emergency_type === '')||
              (userStore.newTicket.description === ''))"
      class="z-[100] mb-[10px] w-[270px] text-white flex flex-col justify-center items-center direction-horizontal h-[35px] border-black bg-cement-orange-non-active">
    <button @click="markUnfilled()" class="min-w-full p-2">
      Отправить
    </button>
  </div>
</template>